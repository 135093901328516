<template>
  <base-view icon="calendar" title="Períodos fiscales" header-type="compact">
    <!-- Formulario para la modificación del periodo fiscal -->
    <check-authorization :requiresAuthorizations="[permissions.update]" hide>
      <b-modal
        id="periodosFiscalesModal"
        centered
        title="Título"
        no-close-on-backdrop
        hide-header-close
        hide-footer
      >
        <template #modal-title>Editar periodo fiscal</template>
        <template #default="{cancel}">
          <div class="d-block">
            <fiscal-periods-form
              target="update"
              :updateData="getCurrentEditing"
              @on-updated="cancel()"
            >
              <template #btn-cancel-place>
                <b-button
                  variant="black"
                  size="sm"
                  @click="
                    cancel()
                    setCurrentFiscalPeriod(null)
                  "
                >
                  Cancelar
                </b-button>
              </template>
            </fiscal-periods-form>
          </div>
        </template>
      </b-modal>
    </check-authorization>

    <!-- Formulario para la creación con copia de catálogos entre períodos fiscales -->
    <check-authorization :requiresAuthorizations="[permissions.create]" hide>
      <b-modal
        id="periodosFiscalesCreateModal"
        centered
        title="Título"
        no-close-on-backdrop
        hide-header-close
        hide-footer
      >
        <template #modal-title>Seleccionar catálogos a copiar</template>
        <template #default="{cancel}">
          <div class="d-block">
            <fiscal-periods-copy-form
              target="create"
              @on-updated="cancel()"
            >
              <template #btn-cancel-place>
                <b-button
                  variant="black"
                  size="sm"
                  @click="
                    cancel()
                    setCurrentFiscalPeriodForCopy(null)
                  "
                >
                  Cancelar
                </b-button>
              </template>
            </fiscal-periods-copy-form>
          </div>
        </template>
      </b-modal>
    </check-authorization>

    <!-- Formulario para la copia de catálogos entre períodos fiscales -->
    <check-authorization :requiresAuthorizations="[permissions.create]" hide>
      <b-modal
        id="periodosFiscalesCopyModal"
        centered
        title="Título"
        no-close-on-backdrop
        hide-header-close
        hide-footer
      >
        <template #modal-title>Seleccionar catálogos a copiar</template>
        <template #default="{cancel}">
          <div class="d-block">
            <fiscal-periods-copy-form
              target="update"
              :updateData="getCurrentEditingForCopy"
              @on-updated="cancel()"
            >
              <template #btn-cancel-place>
                <b-button
                  variant="black"
                  size="sm"
                  @click="
                    cancel()
                    setCurrentFiscalPeriodForCopy(null)
                  "
                >
                  Cancelar
                </b-button>
              </template>
            </fiscal-periods-copy-form>
          </div>
        </template>
      </b-modal>
    </check-authorization>

    <!-- Lista de periodos fiscales -->
    <div class="card card-header-actions">
      <div class="card-header">
        Períodos fiscales
        <check-authorization
          :requiresAuthorizations="[permissions.create]"
          :show-alert="false"
          no-slots
          #default="{ authorized, message }"
        >
          <span v-b-tooltip.top :title="!authorized ? message : ''">
            <button
              @click="$isAuthorized(authorized, createFiscalPeriod)"
              class="btn btn-success btn-sm"
              :disabled="!authorized || isCreatingResource"
            >
              Crear
            </button>
          </span>
        </check-authorization>
      </div>
      <div class="card-body p-2">
        <check-authorization :requiresAuthorizations="[permissions.list]">
          <fiscal-periods-list :permissions="permissions" />
        </check-authorization>
      </div>
    </div>
  </base-view>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import FiscalPeriodsForm from '@/components/PeriodosFiscales/FiscalPeriodsForm'
import FiscalPeriodsCopyForm from '@/components/PeriodosFiscales/FiscalPeriodsCopyForm'
import FiscalPeriodsList from '@/components/PeriodosFiscales/FiscalPeriodsList'

export default {
  name: 'PeriodosFiscales',

  components: {
    FiscalPeriodsForm,
    FiscalPeriodsCopyForm,
    FiscalPeriodsList
  },

  created () {
    this.permissions = this.$router.currentRoute.meta.permissions
  },

  data () {
    return {
      permissions: {}
    }
  },

  computed: {
    ...mapState('fiscalPeriodsModule', ['isCreatingResource']),
    ...mapGetters('fiscalPeriodsModule', [
      'isEditingResource',
      'isCopyingResource',
      'getCurrentEditing',
      'getCurrentEditingForCopy',
      'getLastFiscalPeriod'
    ])
  },

  watch: {
    getCurrentEditing: {
      immediate: true,
      handler () {
        if (!this.isEditingResource) return

        this.$bvModal.show('periodosFiscalesModal')
      }
    },
    getCurrentEditingForCopy: {
      immediate: true,
      handler () {
        if (!this.isCopyingResource) return

        this.$bvModal.show('periodosFiscalesCopyModal')
      }
    }
  },

  methods: {
    ...mapMutations('fiscalPeriodsModule', ['setCurrentFiscalPeriod', 'setCurrentFiscalPeriodForCopy']),

    /**
     * Agrega un registro más a los periodos fiscales.
     *
     * Agrega un registro más, toma el último año y le suma 1,
     * posteriormente el usuario puede editar su techo financiero
     * y sus campos.
     */
    async createFiscalPeriod () {
      // Emitimos el modal para selección de catálogos a copiar y delegamos acción
      this.$bvModal.show('periodosFiscalesCreateModal')
    }
  }
}
</script>
